.uq__box {
  margin: 20px 0; }

.uq__question-box {
  background-color: #4169E1;
  padding: 10px 15px 10px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer; }

.uq__question {
  color: #fff;
  font-weight: lighter; }

.uq__arrow-img {
  width: 18px;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
  right: 3%;
  background-color: #ffff;
  border-radius: 10px; }

.uq__down-arrow {
  color: #ffff; }

.uq__answer-box {
  padding: 10px;
  border-radius: 5px;
  background-color: #e6e6ff; }

.uq__answer {
  margin: 5px 0; }

@media (min-width: 768px) and (max-width: 1023px) {
  .uq__wrapper {
    padding: 0 12% 10px; }
  .uq__question-box {
    padding: 15px; }
  .uq__question {
    font-size: 18px; } }

@media (min-width: 1024px) {
  .uq__wrapper {
    padding: 0 10% 10px;
    width: 70%; }
  .uq__question-box {
    padding: 15px; }
  .uq__question {
    font-size: 18px; } }
