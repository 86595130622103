.homepage {
  scroll-behavior: smooth;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: block; }
  .homepage__banner-img {
    height: 450px;
    width: 100%;
    object-fit: cover; }
  .homepage__comunicat {
    margin: 20px;
    border-bottom: 1px solid grey; }
  .homepage__comunicat-img {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin: 20px 0 40px; }
    .homepage__comunicat-img p {
      margin: 5px 0; }
    .homepage__comunicat-img img {
      width: 60px;
      height: 40px;
      object-fit: contain; }
  .homepage__banner-info {
    margin: 0 auto;
    position: absolute;
    width: 80%;
    top: 165px;
    left: 20px;
    border-radius: 6px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .homepage__banner-date {
    background-color: #0d0820;
    width: 50%; }
  .homepage__banner-date {
    color: #ffff;
    margin: 0;
    padding: 5px;
    text-align: center;
    font-weight: lighter;
    font-size: 14px;
    line-height: 1.85;
    letter-spacing: 1px; }
  .homepage__banner-description {
    background-color: #f7fcfe;
    padding: 10px 20px; }
  .homepage__banner-title {
    letter-spacing: normal;
    color: #333333;
    font-size: 17px; }
  .homepage__banner-subtitle {
    color: #404040;
    display: block;
    height: 45px;
    font-size: 15px;
    line-height: 1;
    overflow: hidden; }
  .homepage__read-more-box {
    background-color: #2386b2;
    width: 50%;
    height: 35px; }
  .homepage__read-more-box:hover {
    cursor: pointer;
    background-color: #196080; }
  .homepage__read-more {
    color: #ffff;
    text-align: center;
    padding-top: 8px;
    font-weight: lighter;
    font-size: 15px; }
  .homepage__middle-section {
    background-image: url("../../assets/images/generalPresentation/pattern.png");
    padding: 15px 0; }
  .homepage__middle-section-second {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto; }
  .homepage__card {
    background-color: #ffff;
    width: 70%;
    margin: 10px auto; }
  .homepage__card-link {
    width: 70%; }
    .homepage__card-link a {
      color: black;
      text-decoration: none; }
  .homepage__link-title {
    margin-top: 10px;
    text-align: center; }
  .homepage__link-icon {
    width: 18px;
    margin: 10px 20px 0;
    display: flex; }
  .homepage__middle-link {
    text-decoration: none;
    cursor: pointer; }
  .homepage__monitor-title {
    text-align: center; }
  .homepage__middle-icon {
    width: 30px;
    height: 20px;
    display: flex;
    margin: 20px auto 0; }
  .homepage__middle-section-subtitle {
    text-align: center;
    padding: 20px 5px 10px;
    font-size: 16px;
    color: #0d0820; }
  .homepage__usefull-links {
    background-image: url("../../assets/images/generalPresentation/pattern.png"); }
  .homepage__news, .homepage__usefull-links {
    width: 100%;
    text-align: center;
    background-color: #F5F5F5;
    padding: 40px 0; }
  .homepage__events-and-projects {
    display: flex;
    flex-direction: column; }
  .homepage__general-news {
    padding: 35px 20px; }
  .homepage__general-title {
    text-align: center; }
  .homepage__general-subtitle {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5;
    color: #4d4d4d; }
  .homepage__general-news-pic {
    height: 270px;
    width: 100%;
    object-fit: cover; }
  .homepage__news-date-box {
    background-color: #0d0820;
    width: 50%;
    position: absolute;
    bottom: 4px;
    margin: 0 auto;
    left: 0;
    right: 0; }
  .homepage__news-date {
    color: #ffff;
    font-size: 14px;
    font-weight: lighter;
    padding: 10px 0;
    text-align: center; }
  .homepage__general-pic-box {
    position: relative; }
  .homepage__general-name {
    font-size: 20px;
    text-align: center;
    color: #0d0820;
    padding: 10px 0 3px; }
  .homepage__general-description {
    text-align: left;
    color: #0d0820;
    font-size: 16px;
    width: 70%;
    margin: 0 auto; }
  .homepage__general-button {
    background-color: #2386b2;
    width: 120px;
    height: 40px;
    border: none;
    display: flex;
    margin: 15px auto; }
  .homepage__general-see-more {
    color: #ffff;
    line-height: 1.13;
    font-weight: lighter;
    margin: 0 auto;
    font-size: 15px; }
  .homepage__project {
    background-color: #4169E1; }
  .homepage__project-main-title {
    color: #ffff; }
  .homepage__project-subtitle {
    color: #e6e6e6;
    font-weight: lighter; }
  .homepage__project-date-box {
    background-color: #ffff; }
  .homepage__project-date {
    color: #0d0d0d;
    font-weight: normal; }
  .homepage__project-name {
    color: #ffff; }
  .homepage__project-description {
    color: #cccccc;
    font-weight: lighter; }
  .homepage__usefull-links-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .homepage__usefull-links-card {
    margin: 0 auto;
    width: 80%;
    text-align: center;
    max-width: 400px;
    border-radius: 10px;
    margin-bottom: 5%;
    background-color: grey; }
  .homepage__taxes-section {
    width: 100%;
    padding: 20px; }
  .homepage__taxes {
    padding: 5px 0;
    font-size: 16px; }
  .homepage__link {
    text-decoration: none; }
  .homepage__payment-section {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: red; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .homepage__taxes-section {
      padding: 20px 40px; }
    .homepage__banner-img {
      height: 350px; }
    .homepage__banner-info {
      width: 70%;
      top: 115px;
      left: 90px; }
    .homepage__middle-section {
      padding: 30px 20px; }
    .homepage__middle-section-second {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
    .homepage__card {
      width: 44%; }
    .homepage__monitor-title {
      text-align: left;
      padding-left: 20px; }
    .homepage__card-link {
      height: 70px;
      width: 60%; }
      .homepage__card-link a {
        color: black;
        text-decoration: none; }
    .homepage__link-icon {
      width: 18px;
      margin-left: 30px; }
    .homepage__events-and-projects {
      flex-direction: row; }
    .homepage__general-news {
      width: 50%; }
    .homepage__general-button {
      display: flex;
      margin: 25px 0 15px 20px; } }
  @media (min-width: 1024px) {
    .homepage__payment-section {
      padding: 40px !important;
      font-size: 20px; }
    .homepage__taxes-section {
      padding: 20px 10%;
      width: 80%;
      border-bottom: 1px solid #0d0820; }
    .homepage__taxes {
      font-size: 18px; }
    .homepage__banner-img {
      height: 550px; }
    .homepage__banner-info {
      width: 35%;
      top: 220px;
      left: 10%; }
    .homepage__banner-title {
      font-size: 20px;
      padding: 5px 0 10px; }
    .homepage__read-more-box, .homepage__banner-date {
      width: 40%; }
    .homepage__card {
      width: 13%; }
    .homepage__middle-section {
      padding: 30px 10% 30px;
      justify-content: center; }
    .homepage__link-icon {
      margin-left: 40px; }
    .homepage__card-link {
      width: 25%;
      cursor: pointer; }
    .homepage__events-and-projects {
      flex-direction: row;
      justify-content: center; }
    .homepage__general-news {
      width: 50%; }
    .homepage__general-news-pic {
      width: 70%;
      height: 350px;
      margin: 0 auto;
      display: flex; }
    .homepage__news-date-box {
      width: 30%;
      bottom: 0; }
    .homepage__project-date {
      font-weight: bold; }
    .homepage__general-news {
      width: 45%; }
    .homepage__comunicat {
      padding: 30px 10% 30px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .homepage__comunicat p {
        margin: 8px 0; }
    .homepage__comunicat-img {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 60%;
      margin-bottom: 40px; }
      .homepage__comunicat-img img {
        width: 160px;
        height: 100px;
        object-fit: contain; }
    .homepage__general-button {
      width: 150px; }
    .homepage__monitor-title {
      font-size: 24px;
      padding: 0 0 10px 25px; }
    .homepage__card-link {
      width: 30%;
      padding-bottom: 20px; }
    .homepage__middle-section-second {
      display: flex;
      flex-direction: row; }
    .homepage__monitor-title {
      text-align: left; } }

.project-overlay {
  background: #1D64F0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity .5s;
  border-radius: 15px; }
