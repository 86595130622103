.contact-page__content {
  width: 100%; }

.contact-page__item, .contact-page__item--full-width {
  padding: 30px 20px; }

.contact-page__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: #0c0624;
  margin-left: 0 !important; }

.contact-page__individual-method {
  display: flex;
  flex-direction: row;
  margin: 15px auto;
  align-items: center; }

.contact-page__audience {
  background-color: #ffff;
  padding: 10px 3px;
  margin-bottom: 10px; }

.contact-page #audience-info {
  color: #0c0624;
  font-size: 16px; }

.contact-page__info-img {
  width: 20px; }

.contact-page__info {
  color: #333333;
  padding-left: 10px; }

.contact-page__schedule-info {
  display: flex;
  flex-direction: row;
  margin-top: 20px; }

.contact-page__schedule-details {
  width: 50%; }

.contact-page__schedule {
  background-color: #0d0820; }

.contact-page__press {
  margin: 25px 0 15px; }

.contact-page__petition {
  background-image: url("../../assets/images/generalPresentation/pattern.png"); }

.contact-page__form {
  display: flex;
  flex-direction: column;
  margin: 20px; }

.contact-page__field {
  border: none;
  border-bottom: 1px solid #e0eaee;
  padding: 25px 3px 3px;
  font-size: 16px; }
  .contact-page__field:focus {
    outline: 0; }

.contact-page__field::placeholder {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: bolder;
  color: #0c0624; }

.contact-page__button {
  margin: 20px 0;
  background-color: #2386b2;
  border: none;
  height: 40px;
  cursor: pointer; }

.contact-page__button-writing {
  color: #ffff;
  font-size: 14px;
  line-height: 1.13; }

.contact-page__info-link {
  text-decoration: none; }

.contact-page__email {
  padding-top: 10px; }
  .contact-page__email a {
    text-decoration: none;
    color: #333333; }

.contact-page #switch {
  color: #ffff;
  font-weight: lighter; }

@media (min-width: 768px) and (max-width: 1023px) {
  .contact-page__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .contact-page__item--full-width {
    width: 100%;
    padding: 0; }
  .contact-page__item {
    width: 50%;
    padding: 0; }
  .contact-page__item-box {
    padding: 30px 20px; } }

@media (min-width: 1024px) {
  .contact-page__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; }
  .contact-page__item {
    width: 50%;
    padding: 0; }
  .contact-page__item--full-width {
    width: 100%;
    padding: 0; }
  .contact-page__item-box {
    padding: 30px 20px; }
  .contact-page__item-box-left {
    padding-left: 20%; }
  .contact-page__item-box-left--full-width {
    padding-left: 10%; }
  .contact-page__item-box-right {
    padding-left: 8%; }
  .contact-page__audience {
    width: 70%; }
  .contact-page__field {
    font-size: 18px; } }
