#heating-aids-list li {
  color: #ffff;
  font-weight: lighter; }

.heating-aids {
  position: relative;
  margin: 0 auto; }
  .heating-aids__subtitle {
    text-indent: 10px;
    margin: 5px 0;
    font-size: 14px;
    border: 1px solid #000080;
    padding: 5px; }
  .heating-aids__info {
    margin: 10px 0; }
  .heating-aids__left-side {
    padding: 20px 30px; }
  .heating-aids__right-side {
    background-color: #4169E1;
    padding: 20px 10px 20px 0;
    color: #ffff; }
    .heating-aids__right-side .standard__see-project {
      color: white; }
  .heating-aids__main-title {
    font-size: 25px;
    letter-spacing: -0.5px;
    margin-top: 8px;
    text-align: center;
    font-weight: lighter; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .heating-aids {
      display: flex;
      flex-direction: row; }
      .heating-aids__left-side, .heating-aids__right-side {
        width: 50%; } }
  @media (min-width: 1024px) {
    .heating-aids {
      display: flex;
      flex-direction: row; }
      .heating-aids__left-side, .heating-aids__right-side {
        width: 50%; }
      .heating-aids__document-list {
        padding-left: 100px;
        margin-top: 20px; }
      .heating-aids__left-side-small {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto; } }
  .heating-aids #right-main-title {
    text-align: left;
    margin-left: 20%; }
  .heating-aids #heating-aids-list {
    width: 80%; }
    .heating-aids #heating-aids-list li {
      color: #ffff;
      font-weight: lighter;
      font-size: 18px; }
