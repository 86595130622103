.banner {
  position: relative;
  height: 150px; }
  .banner__image {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .banner__title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    top: 40%;
    font-size: 1.3em; }
  .banner__flag {
    display: none; }

@media (min-width: 768px) and (max-width: 1023px) {
  .banner {
    height: 300px; }
    .banner__title {
      font-size: 1.5em; } }

@media (min-width: 1024px) {
  .banner {
    height: 350px; }
    .banner__title {
      font-size: 2em;
      top: 50%;
      max-width: 80%;
      margin: 0 auto; }
    .banner__flag {
      display: block;
      height: 100px;
      position: absolute;
      top: 10%;
      left: 10%; } }
